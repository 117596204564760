body{
  background: #333;
}

header{
  background: #111;
  color: #fff;
  margin: 0 -15px auto;
  padding: 1em;

}

.loginform {
  padding: 1em;
  margin: 1em auto;
  max-width: 500px;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.25);
}

.imgLoading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* bottom: 0; */
  background-color: rgb(49, 80, 113);
  height: 80px;
  color: #fff;
  text-align: center;
  padding: 25px;
}

.loadingIndicator {
  animation: blinker 1s linear infinite;
  width: 10px;
  height: 10px;
  background: #fff;
  border: 1px solid #fff;
  display: inline-block;
  margin-left: 10px;
  border-radius: 2px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
